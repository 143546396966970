import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/tarefas/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },

    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/tarefas', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('descricao', itemData.descricao)
      formData.append('classes[]', itemData.classes.id || itemData.classes.uuid)

      const listaSubdisciplinas = itemData.subdisciplinas.map(item => {
        if (item.id) {
          return item.id
        }
        return item.uuid
      })

      Object.keys(listaSubdisciplinas)
        .forEach(chave => {
          formData.append('subdisciplinas[]', listaSubdisciplinas[chave])
        })

      return new Promise((resolve, reject) => {
        axios
          .post('/tarefas', formData)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`tarefas/${item.id.uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      const formData = new FormData()

      let classeEscolhida = itemData.classes

      if (typeof itemData.classes === 'object') {
        classeEscolhida = itemData.classes[0].uuid || itemData.classes[0].id
      }
      formData.append('classes[]', classeEscolhida)
      formData.append('descricao', itemData.descricao)

      const listaSubdisciplinas = itemData.sub_disciplinas.map(item => {
        if (item.id) {
          return item.id
        }
        return item.uuid
      })

      Object.keys(listaSubdisciplinas)
        .forEach(chave => {
          formData.append('subdisciplinas[]', listaSubdisciplinas[chave])
        })

      formData.append('_method', 'PUT')
      return new Promise((resolve, reject) => {
        axios
          .post(`tarefas/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
